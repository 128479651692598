import Vue from 'vue';
import Vuex from 'vuex';

import parcel from './modules/parcel';
import water from './modules/water';
import address from './modules/address';
import admin from './modules/admin';
import septic from './modules/septic';
import feature from './modules/feature';
import hvac from './modules/hvac';
import tax from './modules/tax';
import solar from './modules/solar';
import styleModule from './modules/style';
import selection from './modules/selection';
import _ from 'lodash';

import Organization from '../services/organization';

const defaultSnackbar = {
  bottom: true,
  right: true,
  color: "info",
  message: "",
  timeout: 5000,
  value: false
}

const getLocal = key => {
  let item = localStorage.getItem(key);
  if (!item) {
    return null;
  }
  try {
    return JSON.parse(item);
  } catch {
    return null;
  }
};

const getSession = (key) => {
  let item = sessionStorage.getItem(key);
  if (!item) {
    return null;
  }
  try {
    return JSON.parse(item);
  } catch {
    return null;
  }
}

Vue.use(Vuex);

let loggedInAs = localStorage.getItem('loggedInAccount');
try {
  loggedInAs = JSON.parse(loggedInAs);
} catch {
  loggedInAs = null;
}
let defaultPermissions = localStorage.getItem('permissions');
try {
  defaultPermissions = JSON.parse(defaultPermissions);
} catch {
  defaultPermissions = null;
}

let orgFeatures = localStorage.getItem('orgFeatures');
try {
  orgFeatures = JSON.parse(orgFeatures);
} catch {
  orgFeatures = null;
}

let userProfile = localStorage.getItem('userProfile');
if (userProfile) {
  try {
    userProfile = JSON.parse(userProfile);
  } catch { }
} else {
  userProfile = null;
}

const organizationSetting = Organization.setting(getLocal('organizationSetting'));
const organizations = getLocal('organizations');

export default new Vuex.Store({
  modules: {
    parcel,
    water,
    address,
    septic,
    feature,
    hvac,
    tax,
    admin: {
      namespaced: true,
      ...admin
    },
    solar: {
      namespaced: true,
      ...solar
    },
    style: {
      namespaced: true,
      ...styleModule
    },
    selection: {
      namespaced: true,
      ...selection
    }
  },

  state: {
    auth: !!localStorage.getItem('auth'),
    isAdmin: !!localStorage.getItem('admin'),
    isSolarAdmin: !!localStorage.getItem('solarAdmin'),
    user: userProfile || {
      email: '',
      displayName: '',
      uid: localStorage.getItem('uid') || '',
      profile: {}
    },
    features: [],
    cpi: [],
    documents: [],
    organizations: organizations || [],
    loadingOrganizations: false,
    permissions: defaultPermissions || {
      create: true,
      read: true,
      update: true,
      delete: true,
      addMember: true
    },
    organizationFeatures: orgFeatures || {},
    documentFilter: '',
    teams: null,
    redirect: true,
    loggedInAs: loggedInAs || {
      name: '',
      key: '',
      id: '',
      type: ''
    },
    drawer: false,
    appBarTitle: localStorage.getItem('appBarTitle') || 'My Peeps Portal',
    organizationSetting: organizationSetting || {
      ...Organization.defaultSetting()
    },
    gps: null,
    orientation: getSession('orientation') || null,
    orientationPermission: false,
    landPreserves: [],
    cmsPages: getLocal('cmsPages') || {
      Nav_Page_Home: false,
      Nav_Page_About: false,
      Nav_Page_Help: false
    },
    preference: getLocal('preference') || {
      neverShowLocationPermissionError: false
    },
    dialogs: {
      locationPermissionError: false,
      locationPermission: false,
      orientationPermission: false,
      locationPermissionTroubleshoot: false,
      locationPermissionTroubleshootButton: false
    },
    snackbar: {
      ...defaultSnackbar
    }
  },
  getters: {
    auth(state) {
      return state.auth;
    },
    isAdmin(state) {
      return state.isAdmin;
    },
    isSolarAdmin(state) {
      return state.isSolarAdmin;
    },
    user(state) {
      return state.user;
    },
    parcelSetting(state) {
      /**
       * team: if team setting is enabled or not
       */

      const res = {};
      if (state.loggedInAs.type === 'organization') {
        res.team = state.organizationSetting.team;
      } else {
        res.team = state.user?.profile?.enableTeams;
      }
      return res;
    },
    features(state) {
      return state.features;
    },
    cpi(state) {
      return state.cpi;
    },
    documents(state) {
      let docs = [...state.documents];
      if (state.documentFilter == 'na') {
        docs = docs.filter(file => !file.feature);
      } else if (state.documentFilter) {
        docs = docs.filter(file => _.lowerCase(file.feature) == _.lowerCase(state.documentFilter));
      }
      return docs;
    },
    documentFilter(state) {
      return state.documentFilter;
    },
    teams(state) {
      return state.teams;
    },
    parcelReference(state) {
      const { type } = state.loggedInAs;
      if (type === 'organization') {
        return state.organizationSetting.parcelReference;
      } else {
        return state.user?.profile?.includeParcelReference || false;
      }
    }
  },
  mutations: {
    auth(state, data) {
      if (data) {
        localStorage.setItem('auth', data);
      } else {
        localStorage.removeItem('auth');
      }
      state.auth = data;
    },
    admin(state, data) {
      state.isAdmin = data;
    },
    solarAdmin(state, data) {
      state.solarAdmin = data;
    },
    user(state, data) {
      state.user = data;
      localStorage.setItem('userProfile', JSON.stringify(data));
    },
    updateUserByKey(state, { key, value }) {
      state.user[key] = value;
    },
    userProfileKey(state, { key, value }) {
      state.user.profile[key] = value;
    },
    features(state, data) {
      state.features = data;
    },
    cpi(state, data) {
      state.cpi = data;
    },
    documents(state, data) {
      state.documents = data;
    },
    teams(state, data) {
      state.teams = data;
    },
    userFeatures(state, data) {
      state.user.profile.features = data;
    },
    setDocumentFilter(state, value) {
      state.documentFilter = value;
    },
    redirect(state, value) {
      state.redirect = value;
    },
    setOrganizations(state, data) {
      state.organizations = data;
      state.loadingOrganizations = false;
      localStorage.setItem('organizations', JSON.stringify(data));
    },
    loginAsUser(state) {
      const name = `${state.user?.profile?.first_name || ''}`;
      const accountInfo = { type: 'user', id: state.user.uid, name };
      state.loggedInAs = accountInfo;
      localStorage.setItem('loggedInAccount', JSON.stringify(accountInfo));
    },
    loginAsOrganization(state, { id, name, key }) {
      const accountInfo = { type: 'organization', id, name, key };
      state.loggedInAs = accountInfo;
      localStorage.setItem('loggedInAccount', JSON.stringify(accountInfo));
    },
    drawer(state, value) {
      state.drawer = value;
    },
    loadingOrganizations(state, value) {
      state.loadingOrganizations = value;
    },
    updatePermission(state, { permission, value }) {
      state.permissions[permission] = value;
      localStorage.setItem('permissions', JSON.stringify(state.permissions));
    },
    setOrganizationFeatures(state, value) {
      state.organizationFeatures = value;
      localStorage.setItem('orgFeatures', JSON.stringify(state.organizationFeatures));
    },
    updateOrganizationFeature(state, { key, value }) {
      state.organizationFeatures[key] = value;
      localStorage.setItem('orgFeatures', JSON.stringify(state.organizationFeatures));
    },
    resetOrgFeatures(state) {
      state.organizationFeatures = {};
    },
    appBarTitle(state, title) {
      state.appBarTitle = title;
      localStorage.setItem('appBarTitle', title);
    },
    organizationSetting(state, setting) {
      localStorage.setItem('organizationSetting', JSON.stringify(setting));
      state.organizationSetting = Organization.setting(setting);
    },
    setGps(state, value) {
      state.gps = value;
    },
    setOrientation(state, value) {
      state.orientation = value;
      sessionStorage.setItem("orientation", JSON.stringify(value))
    },
    landPreserves(state, value) {
      state.landPreserves = value;
    },
    cmsPages(state, { page, value }) {
      state.cmsPages[page] = value;
      localStorage.setItem('cmsPages', JSON.stringify(state.cmsPages));
    },
    setPreference(state, { key, value }) {
      state.preference[key] = value;
      localStorage.setItem('preference', JSON.stringify(state.preference));
    },
    setDialog(state, { key, value }) {
      state.dialogs[key] = value;
    },
    setOrientationPermission(state, value) {
      state.orientationPermission = value;
    },
    updateSnackbar(state, { key, value }) {
      state.snackbar[key] = value
    },
    resetSnackbar(state) {
      Object.keys(defaultSnackbar).forEach(key => {
        state.snackbar[key] = defaultSnackbar[key]
      });
    }
  },
  actions: {
    showSnackbar(context, data) {
      Object.keys(data || {}).forEach(key => {
        context.commit("updateSnackbar", { key, value: data[key] })
      });
      context.commit("updateSnackbar", { key: 'value', value: true })
      setTimeout(() => {
        context.commit("resetSnackbar")
      }, context.state.snackbar.timeout);
    }
  }
});
